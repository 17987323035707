import React, { useCallback } from 'react'
import algoliasearch from 'algoliasearch/lite'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import { graphql, Link } from 'gatsby'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  connectHits,
  InstantSearch,
  SearchBox,
  connectHighlight,
  Snippet,
} from 'react-instantsearch-dom'
import Layout from '../components/layout'
import { Container, Row, Col } from 'reactstrap'
import header from '../images/CleanAir-Desktop-Header-1920x340-Curve-Disclaimer-2x.png'
import headerMobile from '../images/CleanAir-Mobile-Header-1080x550-Curve-Disclaimer-2x.png'

const algoliaClient = algoliasearch(
  'EB3K2V2T3G',
  'ab071a9329d9fc8fd91760e5d99d137d'
)

const searchClient = {
  search(requests) {
    const shouldSearch = requests.some(({ params: { query } }) => query !== '')
    if (shouldSearch) {
      return algoliaClient.search(requests)
    }
    return Promise.resolve({
      results: [{ hits: [] }],
    })
  },
  searchForFacetValues: algoliaClient.searchForFacetValues,
}

const Highlight = ({ hit }) => {
  //  return <Snippet hit={hit} attribute="text" />
  if (
    hit &&
    hit._snippetResult &&
    hit._snippetResult.text &&
    Array.isArray(hit._snippetResult.text)
  ) {
    return (
      <div className="">
        {hit._snippetResult.text.map((part, index) => {
          return part.matchLevel !== 'none' ? (
            <span
              dangerouslySetInnerHTML={{
                __html:
                  part.value
                    .replace('<ais-highlight-0000000000>', '<strong>')
                    .replace('</ais-highlight-0000000000>', '</strong>') + ' ',
              }}
            />
          ) : null
        })}
      </div>
    )
  } else {
    return null
  }
}

const CustomHighlight = connectHighlight(Highlight)

const Hits = connectHits(({ hits }) => {
  return (
    <div css={{ display: 'flex', flexWrap: 'wrap' }}>
      {/* Always use a ternary when coercing an array length */}
      {/* otherwise you might print out "0" to your UI */}
      {hits.length ? (
        <>
          {hits.map(hit => {
            return (
              <div key={hit.objectID}>
                {hit.title && (
                  <div className="search-hit">
                    <div className="pr-3 w-100">
                      <h4>
                        {hit.title
                          ? hit.title.replace(
                              '| Clean Air Greater Manchester',
                              ''
                            )
                          : 'No title'}
                      </h4>
                      <div class="d-none d-lg-block">
                        {hit.text ? (
                          <div>
                            <CustomHighlight
                              attribute="text"
                              hit={hit}
                              tagName="strong"
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <Link
                      to={hit.url.replace('https://cleanairgm.com', '')}
                      className="btn btn-slider-glass"
                    >
                      View
                    </Link>
                  </div>
                )}
              </div>
            )
          })}
        </>
      ) : (
        <p>There were no results for your query. Please try searching.</p>
      )}
    </div>
  )
})
class Search extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      searchState: {},
    }
    this.setSearchState = this.setSearchState.bind(this)
  }

  componentDidMount() {
    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.searchTerm
    ) {
      this.setState({
        searchState: { query: this.props.location.state.searchTerm },
      })
    }
  }

  setSearchState(state) {
    this.setState({ searchState: state })
  }

  render() {
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')

    return (
      <Layout>
        <div style={{ background: '#fff' }}>
          <Helmet title={`Search | ${siteTitle}`} />
          <div className="page-banner page-banner--disclaimer">
            <img className="d-none d-lg-block" src={header} alt="" />
            <img className="d-block d-lg-none" src={headerMobile} alt="" />
            <h1>Search</h1>
          </div>

          <Container className={`news mt-5 mb-5`}>
            <Row>
              <Col sm={12} md={{ size: 8, offset: 2 }}>
                <InstantSearch
                  indexName={"netlify_8ad80982-1dc9-4094-bab4-52741611d196_live_all"}
                  searchClient={searchClient}
                  searchState={this.state.searchState}
                  onSearchStateChange={this.setSearchState}
                >
                  <SearchBox className="mb-4" />
                  <Hits />
                </InstantSearch>
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    )
  }
}

export default Search

export const pageQuery = graphql`
  query SearchPageQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
